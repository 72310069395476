import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import DetailedView from 'components/DetailedView/DetailedView';
import TradesTable from './TradeTable';

const TradesTab = () => {
	return (
		<div className="user-management">
			<Switch>
				<Route exact path={ROUTES.transfersAndTradeReports.trades.index}>
					<TradesTable />
				</Route>
				<Route
					path={`${ROUTES.transfersAndTradeReports.trades.index}/:id`}
					component={DetailedView}
				/>
				<Redirect to={ROUTES.transfersAndTradeReports.trades.index} />
			</Switch>
		</div>
	);
};
export default TradesTab;
