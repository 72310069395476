import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOneUserRequest } from 'redux/reducers/users/reducer';
import { getOneUser } from 'redux/reducers/users/selectors';
import Loading from 'layouts-elements/Loading/Loading';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import DefaultFields from '../DefaultFields/DefaultFields';
import TradeDetailsForm from '../TradeDetailsForm/TradeDetailsForm';
import {
	getDetailedViewLoading,
	getTradeDetails,
} from '../../../redux/reducers/detailedView/selectors';
import { getTradeDetailsRequest } from '../../../redux/reducers/detailedView/reducer';

export interface ITradeDetails {
	txId: string | number;
	userId: string | number;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const TradeDetails: FC<ITradeDetails> = ({ txId, userId }) => {
	const dispatch = useDispatch();
	const tradeDetails = useSelector(getTradeDetails);
	const userData = useSelector(getOneUser);
	const loading = useSelector(getDetailedViewLoading);
	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	useEffect(() => {
		dispatch(getOneUserRequest({ userId }));
	}, [dispatch, userId]);

	useEffect(() => {
		if (txId && userId) {
			dispatch(getTradeDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId, userId]);

	const [oneText, twoText] = splitText(tradeDetails?.pair || 'btc_eur');

	return (
		<>
			{tradeDetails && !loading && userData ? (
				<div className="detailed-view" key={tradeDetails.id}>
					<div className="input-wrap--col-2">
						<DefaultFields userData={userData} item={tradeDetails} />
						<CustomInput
							label="Sell"
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							value={`${tradeDetails?.from_asset?.code.toUpperCase()} ${
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								tradeDetails?.from_asset?.name
							}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="Buy"
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							value={`${tradeDetails?.to_asset?.code.toUpperCase()} ${
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								tradeDetails?.to_asset?.name
							}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="Amount Sold"
							value={`${roundingNumber(tradeDetails?.quantity, tradeDetails?.from_asset?.code)} ${
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								tradeDetails?.from_asset?.code.toUpperCase()
							}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="Amount Received"
							value={`${roundingNumber(tradeDetails?.amount, tradeDetails?.to_asset?.code)} ${
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								tradeDetails?.to_asset?.code.toUpperCase()
							}`}
							type="text"
							disabled
						/>
						{/* <CustomInput
							label="Credit Gross"
							value={`${roundingNumber(tradeDetails?.amount, tradeDetails?.to_asset?.code)} ${
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								tradeDetails?.to_asset?.code.toUpperCase()
							}`}
							type="text"
							disabled
						/> */}
						<CustomInput
							label="Credit Fee"
							value={`${roundingNumber(tradeDetails?.credit_fee, tradeDetails?.to_asset?.code)} ${
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								tradeDetails?.to_asset?.code.toUpperCase()
							}`}
							type="text"
							disabled
						/>
						{/* <CustomInput label="Credit Net" value="" type="text" disabled /> */}
						{/* <CustomInput label="Debit Gross" value="" type="text" disabled /> */}
						<CustomInput
							label="Debit Fee"
							value={`${roundingNumber(tradeDetails?.debit_fee, tradeDetails?.from_asset?.code)} ${
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								tradeDetails?.from_asset?.code.toUpperCase()
							}`}
							type="text"
							disabled
						/>
						{/* <CustomInput label="Debit Net" value="" type="text" disabled /> */}
						<CustomInput
							label="Exchange Rate"
							value={`${roundingNumber(tradeDetails?.price_b2c2, twoText)} ${
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								twoText.toUpperCase()
							}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="Trade Fee"
							value={`${roundingNumber(
								tradeDetails.fee,
								tradeDetails?.from_asset?.code,
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							)} ${tradeDetails?.from_asset?.code.toUpperCase()}`}
							type="text"
							disabled
						/>

						<CustomInput label="Tx ID" value={tradeDetails.id} type="text" disabled />

						<CustomInput
							label="B2C2 Trade ID"
							value={tradeDetails.liquidity_provider_id}
							type="text"
							disabled
						/>
						<CustomInput
							label="Oneify ID"
							value={tradeDetails?.user?.oneify_id}
							type="text"
							disabled
						/>
					</div>
					<TradeDetailsForm item={tradeDetails} />
				</div>
			) : (
				<>{loading ? <Loading /> : <div>No data</div>}</>
			)}
		</>
	);
};

export default TradeDetails;
