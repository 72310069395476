import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOneUser } from '../../../redux/reducers/users/selectors';
import { getOneUserRequest } from '../../../redux/reducers/users/reducer';
import Loading from '../../../layouts-elements/Loading/Loading';
import DetailedViewTransfersCryptoForm from '../DetailedViewTransfersCryptoForm/DetailedViewTransfersCryptoForm';
import { getCryptoDetailsRequest } from '../../../redux/reducers/detailedView/reducer';
import {
	getCryptoDetails,
	getDetailedViewLoading,
} from '../../../redux/reducers/detailedView/selectors';

export interface ITransfersFiatDetails {
	txId: string | number;
	userId: string | number;
}

const TransfersCryptoDetails: FC<ITransfersFiatDetails> = ({ txId, userId }) => {
	const dispatch = useDispatch();
	const userData = useSelector(getOneUser);
	const loading = useSelector(getDetailedViewLoading);
	const cryptoDetails = useSelector(getCryptoDetails);

	useEffect(() => {
		dispatch(getOneUserRequest({ userId }));
	}, [dispatch, userId]);

	useEffect(() => {
		if (txId && userId) {
			// const params: any = {
			// 	userId,
			// 	// type: EApiTransactionType.TRANSFER,
			// 	current_page: 1,
			// 	per_page: 1,
			// 	id: Number(txId),
			// };
			// dispatch(getCryptoTransfersRequest(params));
			dispatch(getCryptoDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId, userId]);

	return (
		<>
			{cryptoDetails && !loading && userData ? (
				<div className="detailed-view" key={cryptoDetails.id}>
					<DetailedViewTransfersCryptoForm userData={userData} item={cryptoDetails} />
				</div>
			) : (
				<>{loading ? <Loading /> : <div>No data</div>}</>
			)}
		</>
	);
};

export default TransfersCryptoDetails;
