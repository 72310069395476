/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FC } from 'react';
import { IUser } from 'redux/reducers/users/types';

interface IManageUsersRow {
	data: IUser;
	checked: boolean;
	checkedUsers: number[];
	onCheckSingleUser: (id: number, target: boolean) => void;
	disabled: boolean;
}

const ManageUsersRow: FC<IManageUsersRow> = ({
	data,
	checked,
	checkedUsers,
	onCheckSingleUser,
	disabled,
}) => {
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Checkbox</p>
				<input
					type="checkbox"
					onChange={(event) => onCheckSingleUser(data.id, event.target.checked)}
					checked={checkedUsers.includes(data.id)}
					disabled={disabled}
				/>
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p className="td-name--flex">{data.id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Oneify ID</p>
				<p className="td-name--flex">{data.oneify_id || ''}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Full Name</p>
				<p className="td-name--flex">{`${data.data.first_name} ${data?.data.last_name}`}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<p className="td-name--flex">{data.email}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Company Name</p>
				<p className="td-name--flex">{data.data.company_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Country</p>
				<p className="td-name--flex">
					{data.type === 'corporate' && data.data.company_country_registration}
				</p>
			</div>
		</div>
	);
};

export default ManageUsersRow;
