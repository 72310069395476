import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import {
	EApiCryptoTransactionStatus,
	IApiCryptoHistoryItem,
} from 'services/api/transactions/types';
import Input from 'ui/Formik/Input';
import InputFile from 'ui/Formik/InputFile/InputFile';
import { CloseFileIcon } from 'assets/inline-svg';
import {
	createCryptoDetailsRequest,
	deleteCryptoDetailsFilesRequest,
} from '../../../redux/reducers/detailedView/reducer';
import { IApiGetOneUserResponse } from '../../../services/api/users/types';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import DefaultFields from '../DefaultFields/DefaultFields';

interface IDetailedViewCryptoForm {
	item: IApiCryptoHistoryItem;
	userData: IApiGetOneUserResponse;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const DetailedViewCryptoForm: FC<IDetailedViewCryptoForm> = ({ item, userData }) => {
	const dispatch = useDispatch();
	const [fileList, setFileList] = useState<any>([]);

	const initialValues = {
		client_notes: item.client_notes || '',
		reference: item.reference || item.ext_tx_hash || '',
		description: item.description || item.destination || item?.address.address || '',
		admin_notes: item.admin_notes || '',
	};

	const validationSchema = yup.object().shape({
		admin_notes: yup.string().max(255, 'Maximum length is 255 characters'),
		client_notes: yup.string().max(255, 'Maximum length is 255 characters'),
		reference: yup.string().max(255, 'Maximum length is 255 characters'),
		description: yup.string().max(255, 'Maximum length is 255 characters'),
	});

	const deleteFilesHandler = (file: string) => {
		const payload = {
			id: item.id,
			files: [file],
		};
		dispatch(deleteCryptoDetailsFilesRequest(payload));
	};

	return (
		<div className="detailed-view-form">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(value, { resetForm, setSubmitting }) => {
					setSubmitting(true);
					const formData = new FormData();
					fileList &&
						fileList.length &&
						fileList.forEach((file: File) => formData.append(`files[]`, file));
					const requestBody = {
						id: item.id,
						client_notes: value.client_notes,
						admin_notes: value.admin_notes,
						reference: value.reference,
						description: value.description,
					};
					Object.keys(requestBody).forEach((key) => {
						const valueRequest = requestBody[key as keyof typeof requestBody];
						if (valueRequest !== undefined) {
							formData.append(key, valueRequest.toString());
						}
					});
					dispatch(
						createCryptoDetailsRequest({
							id: item.id,
							body: formData,
						}),
					);
					setFileList([]);
					resetForm();
					setSubmitting(false);
				}}
				enableReinitialize
				validateOnBlur
			>
				{({
					setFieldTouched,
					touched,
					values,
					errors,
					isValid,
					dirty,
					setFieldValue,
					isSubmitting,
					setTouched,
				}) => (
					<>
						<Form>
							<div className="input-wrap--col-2">
								<DefaultFields userData={userData} item={item} />
								<CustomInput
									label="Currency"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${item.asset?.code.toUpperCase()} ${item.asset?.name}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Gross"
									value={`${roundingNumber(
										item.gross_fee,
										item?.asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item?.asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Fee"
									value={`${roundingNumber(
										item.fee,
										item?.asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item?.asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Net"
									value={`${roundingNumber(
										item.net_fee,
										item?.asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item?.asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>

								<CustomInput
									label="Destination"
									value={`${
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-nested-ternary
										item.destination
											? item.destination
											: item?.address?.address
											? item.address.address
											: '-'
									}`}
									type="text"
									disabled
								/>

								<CustomInput
									label="Tx hash"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${item.ext_tx_hash ? item.ext_tx_hash : '-'}`}
									type="text"
									disabled
								/>
								<CustomInput label="Tx ID" value={item.id} type="text" disabled />
								<CustomInput label="Oneify ID" value={item?.user?.oneify_id} type="text" disabled />
								{userData?.type !== 'corporate' && !item.status.includes('pending') && (
									<CustomInput
										label="Status"
										value={
											item.status === EApiCryptoTransactionStatus.ADMIN_PENDING
												? `Pending`
												: // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												  `${item.status
														.charAt(0)
														// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
														.toUpperCase()}${item.status.slice(1)}`
										}
										type="text"
										disabled
									/>
								)}
								{userData?.type === 'corporate' && !item.status.includes('pending') && (
									<CustomInput
										label="Status"
										value={
											item.status === EApiCryptoTransactionStatus.ADMIN_PENDING
												? `Pending`
												: // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												  `${item.status
														.charAt(0)
														// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
														.toUpperCase()}${item.status.slice(1)}`
										}
										type="text"
										disabled
									/>
								)}

								<Field
									title="Description"
									type="text"
									placeholder=""
									name="description"
									component={Input}
									onBlur={() => setFieldTouched('description')}
								/>
								<Field
									title="Reference"
									type="text"
									placeholder=""
									name="reference"
									component={Input}
									onBlur={() => setFieldTouched('reference')}
								/>
							</div>

							<Field
								title="Admin Notes"
								type="textarea"
								placeholder=""
								name="admin_notes"
								component={Input}
								onBlur={() => setFieldTouched('admin_notes')}
							/>
							<Field
								title="Client Notes"
								type="textarea"
								placeholder=""
								name="client_notes"
								component={Input}
								onBlur={() => setFieldTouched('client_notes')}
							/>

							<div className="input">
								<p className="input__name">Upload File</p>
								<InputFile fileList={fileList} setFileList={setFileList} />
							</div>

							<div className="input">
								<p className="input__name">Attached Files</p>
								<div className="input__links">
									{item.files?.length > 0 ? (
										item.files.map((file: any) => {
											return (
												<div className="detailed-view-form__links" key={file}>
													<a href={file} target="_blank" rel="noreferrer">
														{file}
													</a>
													<span onClick={() => deleteFilesHandler(file)}>
														<CloseFileIcon />
													</span>
												</div>
											);
										})
									) : (
										<div> No data</div>
									)}
								</div>
							</div>

							<button
								type="submit"
								className="btn btn-primary btn--full"
								style={{ marginTop: 14 }}
								disabled={isSubmitting}
							>
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		</div>
	);
};

export default DetailedViewCryptoForm;
